export const pageContent = {
  filters: [
    {
      filterName: "All",
      filterTag: "all",
    },
    {
      filterName: "B2B",
      filterTag: "b2b",
    },
    {
      filterName: "B2C",
      filterTag: "b2c",
    },
    {
      filterName: "SaaS",
      filterTag: "saas",
    },
    {
      filterName: "Lead Gen",
      filterTag: "leadgen",
    },
    {
      filterName: "Ecommerce",
      filterTag: "ecommerce",
    },
    {
      filterName: "EDU",
      filterTag: "education",
    },
    {
      filterName: "Digital Security",
      filterTag: "security",
    },
    {
      filterName: "Martech",
      filterTag: "martech",
    },
    {
      filterName: "HCM",
      filterTag: "hcm",
    },
    {
      filterName: "Fintech",
      filterTag: "financial",
    },
    {
      filterName: "Healthcare",
      filterTag: "health",
    },
    {
      filterName: "Subscription",
      filterTag: "subscription",
    },
  ],
  images: [
    {
      imageName: "gov.svg",
      imageAlt: "Gov+ logo",
      imageTag: "b2c saas",
    },
    {
      imageName: "airdna.svg",
      imageAlt: "AirDNA logo",
      imageTag: "b2b saas",
    },
    {
      imageName: "guesty.svg",
      imageAlt: "Guesty logo",
      imageTag: "b2b saas",
    },
    {
      imageName: "writer.svg",
      imageAlt: "Writer logo",
      imageTag: "b2b saas",
    },
    {
      imageName: "sailpoint.svg",
      imageAlt: "SailPoint logo",
      imageTag: "b2b saas security",
    },
    {
      imageName: "netwrix.svg",
      imageAlt: "Netwrix logo",
      imageTag: "b2b saas security",
    },
    {
      imageName: "activtrak.svg",
      imageAlt: "ActivTrak logo",
      imageTag: "b2b saas hcm",
    },
    {
      imageName: "dat.svg",
      imageAlt: "DAT logo",
      imageTag: "b2b saas",
    },
    {
      imageName: "airsculpt.svg",
      imageAlt: "AirSculpt logo",
      imageTag: "d2c health leadgen",
    },
    {
      imageName: "experity.svg",
      imageAlt: "Experity logo",
      imageTag: "b2b saas health",
    },
    {
      imageName: "invoice_simple.svg",
      imageAlt: "Invoice Simple logo",
      imageTag: "b2b saas",
    },
    {
      imageName: "joist.svg",
      imageAlt: "Joist logo",
      imageTag: "b2b saas",
    },
    {
      imageName: "bonterra.svg",
      imageAlt: "Bonterra logo",
      imageTag: "b2b saas",
    },
    {
      imageName: "boost_health_insurance_1.svg",
      imageAlt: "Boost Health Insurance logo",
      imageTag: "b2c leadgen health",
    },
    {
      imageName: "field_pulse.svg",
      imageAlt: "FieldPulse logo",
      imageTag: "b2b saas",
    },
    {
      imageName: "alkami.svg",
      imageAlt: "Alkami logo",
      imageTag: "b2b saas financial",
    },
    {
      imageName: "quickbase.svg",
      imageAlt: "QuickBase logo",
      imageTag: "b2b saas",
    },
    {
      imageName: "bill.svg",
      imageAlt: "Bill.com logo",
      imageTag: "b2b saas financial",
    },
    {
      imageName: "boost_health_insurance.svg",
      imageAlt: "Boost Health Insurance logo",
      imageTag: "b2b saas martech",
    },
    {
      imageName: "plasmidsaurus.svg",
      imageAlt: "PlasmidSaurus logo",
      imageTag: "all",
    },
    {
      imageName: "samsara.svg",
      imageAlt: "Samsara logo",
      imageTag: "b2b saas",
    },
    {
      imageName: "radware.svg",
      imageAlt: "Radware logo",
      imageTag: "b2b saas security",
    },
    {
      imageName: "absorb.svg",
      imageAlt: "Absorb logo",
      imageTag: "b2b saas",
    },
    {
      imageName: "maxio.svg",
      imageAlt: "Maxio logo",
      imageTag: "all",
    },
    {
      imageName: "Podium.svg",
      imageAlt: "Podium logo",
      imageTag: "b2b leadgen saas",
    },
    {
      imageName: "Americor.svg",
      imageAlt: "Americor logo",
      imageTag: "b2c",
    },
    {
      imageName: "Smoky_Mountains.svg",
      imageAlt: "Smoky Mountains logo",
      imageTag: "b2c",
    },
    {
      imageName: "Rapid7.svg",
      imageAlt: "Rapid7 logo ",
      imageTag: "saas security b2b",
    },
    {
      imageName: "7Shifts.svg",
      imageAlt: "7shifts logo",
      imageTag: "b2c",
    },
    {
      imageName: "Legion.svg",
      imageAlt: "Legion logo",
      imageTag: "ecommerce",
    },
    {
      imageName: "SurefireLocal.svg",
      imageAlt: "Surefire Local logo",
      imageTag: "b2b subscription martech saas leadgen",
    },
    {
      imageName: "Algosec.svg",
      imageAlt: "AlgoSec logo ",
      imageTag: "b2b security",
    },
    {
      imageName: "rudderstack.svg",
      imageAlt: "RudderStack logo",
      imageTag: "b2b",
    },
    {
      imageName: "BambooHR.svg",
      imageAlt: "BambooHR logo",
      imageTag: "b2b, hcm",
    },
    {
      imageName: "forter.svg",
      imageAlt: "Forter logo",
      imageTag: "b2b, security",
    },
    {
      imageName: "TechSmith.svg",
      imageAlt: "TechSmith logo",
      imageTag: "b2b b2c ecommerce saas",
    },
    {
      imageName: "Tenable.svg",
      imageAlt: "Tenable logo",
      imageTag: "security saas b2b leadgen ecommerce subscription",
    },
    {
      imageName: "nTopology.svg",
      imageAlt: "nTopology logo",
      imageTag: "b2b",
    },
    {
      imageName: "Monster.svg",
      imageAlt: "Monster logo",
      imageTag: "b2c, hcm",
    },
    {
      imageName: "JupiterOne.svg",
      imageAlt: "JupiterOne logo",
      imageTag: "b2c security",
    },
    {
      imageName: "ServiceMax.svg",
      imageAlt: "ServiceMax logo",
      imageTag: "saas b2b",
    },
    {
      imageName: "Netflix.svg",
      imageAlt: "Netflix logo",
      imageTag: "b2c ecommerce subscription",
    },
    {
      imageName: "pepsi.svg",
      imageAlt: "Pepsi logo",
      imageTag: "ecommerce b2c",
    },
    {
      imageName: "NBA.svg",
      imageAlt: "NBA logo ",
      imageTag: "ecommerce b2c",
    },
    {
      imageName: "five9.svg",
      imageAlt: "Five9 logo",
      imageTag: "software b2b subscription",
    },
    {
      imageName: "R-studio.svg",
      imageAlt: "RStudio logo",
      imageTag: "saas b2b b2c leadgen",
    },
    {
      imageName: "nfl.svg",
      imageAlt: "NFL logo",
      imageTag: "ecommerce b2c",
    },
    {
      imageName: "Naturebox.svg",
      imageAlt: "NatureBox logo",
      imageTag: "ecommerce b2c subscription",
    },
    {
      imageName: "Timedoctor.svg",
      imageAlt: "Time Doctor logo ",
      imageTag: "saas b2b ecommerce subscription",
    },
    {
      imageName: "viasat.svg",
      imageAlt: "Viasat logo",
      imageTag: "b2b b2c leadgen subscription",
    },
    {
      imageName: "Fight_Camp.svg",
      imageAlt: "FightCamp logo",
      imageTag: "b2c ecommerce subscription",
    },
    {
      imageName: "Bull_Guard.svg",
      imageAlt: "BullGuard logo",
      imageTag: "software b2b b2c security subscription saas",
    },
    {
      imageName: "crowdstrike.svg",
      imageAlt: "CrowdStrike logo",
      imageTag: "security saas b2b leadgen subscription",
    },
    {
      imageName: "Ideal_Image_svg.svg",
      imageAlt: "Ideal Image logo",
      imageTag: "health b2c leadgen subscription",
    },
    {
      imageName: "workday.svg",
      imageAlt: "Workday logo",
      imageTag: "saas leadgen b2b financial subscription hcm",
    },
    {
      imageName: "Okta.svg",
      imageAlt: "Okta logo",
      imageTag: "saas b2b leadgen security subscription",
    },
    {
      imageName: "Stich_fix.svg",
      imageAlt: "Stitch Fix logo",
      imageTag: "b2c ecommerce subscription",
    },
    {
      imageName: "Lowes.svg",
      imageAlt: "Lowe's logo",
      imageTag: "ecommerce b2c",
    },
    {
      imageName: "Fanatics.svg",
      imageAlt: "Fanatics logo",
      imageTag: "ecommerce b2c",
    },
    {
      imageName: "Smith_Noble.svg",
      imageAlt: "Smith & Noble logo",
      imageTag: "ecommerce b2c",
    },
    {
      imageName: "Gobble.svg",
      imageAlt: "Gobble logo",
      imageTag: "ecommerce b2c subscription",
    },
    {
      imageName: "American_express.svg",
      imageAlt: "American Express logo",
      imageTag: "financial b2b b2c leadgen",
    },
    {
      imageName: "Rexel.svg",
      imageAlt: "Rexel logo",
      imageTag: "energy b2b ecommerce",
    },
    {
      imageName: "Magazines.com_svg.svg",
      imageAlt: "Magazines.com logo",
      imageTag: "ecommerce b2c subscription",
    },
    {
      imageName: "SRF_Snake_River_Farms.svg",
      imageAlt: "Snake River Farms logo",
      imageTag: " b2c ecommerce",
    },
    {
      imageName: "Accuquote.svg",
      imageAlt: "AccuQuote logo",
      imageTag: "insurance b2c leadgen financial",
    },
    {
      imageName: "Dice.svg",
      imageAlt: "Dice logo",
      imageTag: "saas b2b leadgen ecommerce",
    },
    {
      imageName: "Drive_safely.svg",
      imageAlt: "Drive Safely logo",
      imageTag: "b2c ecommerce",
    },
    {
      imageName: "Pharmacyclics.svg",
      imageAlt: "Pharmacyclics logo",
      imageTag: "health b2c leadgen",
    },
    {
      imageName: "ER_Express.svg",
      imageAlt: "ER Express logo",
      imageTag: "saas b2b health subscription",
    },
    {
      imageName: "Puravida.svg",
      imageAlt: "Pura Vida logo",
      imageTag: "ecommerce b2c",
    },
    // {
    //   imageName: "WillowLane.svg",
    //   imageAlt: "Willow Lane Cabinetry logo",
    //   imageTag: "ecommerce b2b b2c leadgen",
    // },
    {
      imageName: "Fritolay.svg",
      imageAlt: "FritoLay logo",
      imageTag: "ecommerce b2c subscription",
    },
    {
      imageName: "Paycor.svg",
      imageAlt: "Paycor logo",
      imageTag: "financial saass b2b leadgen subscription hcm",
    },
    {
      imageName: "The_RTA_store.svg",
      imageAlt: "The RTA store",
      imageTag: "ecommerce b2b b2c leadgen",
    },
    {
      imageName: "edriving.svg",
      imageAlt: "eDriving logo",
      imageTag: "software b2c ecommerce",
    },
    {
      imageName: "Collections_etc.svg",
      imageAlt: "Collections Etc logo",
      imageTag: "ecomerce b2c ecommerce",
    },
    {
      imageName: "Hagemeyer.svg",
      imageAlt: "Hagemeyer logo",
      imageTag: "ecommerce b2b",
    },
    {
      imageName: "AbbVie.svg",
      imageAlt: "AbbVie logo",
      imageTag: "health b2c leadgen",
    },
    {
      imageName: "ProductPlan.svg",
      imageAlt: "ProductPlan logo",
      imageTag: "saas b2b ecommerce subscription",
    },
    {
      imageName: "DigitalGuardian.svg",
      imageAlt: "Digital Guardian logo",
      imageTag: "security saas b2b leadgen subscription",
    },
    {
      imageName: "Vocera.svg",
      imageAlt: "Vocera logo",
      imageTag: "b2b health subscription saas",
    },
    {
      imageName: "Dialpad.svg",
      imageAlt: "Dialpad logo",
      imageTag: "saas leadgen b2b subscription",
    },
    {
      imageName: "Tipalti.svg",
      imageAlt: "Tipalti logo",
      imageTag: "saas b2b leadgen financial subscription",
    },
    {
      imageName: "gusto.svg",
      imageAlt: "Gusto logo",
      imageTag: "financial saas b2b leadgen ecommerce subscription hcm",
    },
    {
      imageName: "Typeform.svg",
      imageAlt: "Typeform logo",
      imageTag: "saas b2b b2c ecommerce",
    },
    {
      imageName: "RocketLawyer.svg",
      imageAlt: "Rocket Lawyer logo",
      imageTag: "saas b2b b2c ecommerce subscription",
    },
    {
      imageName: "NurtureLife.svg",
      imageAlt: "Nurture Life logo",
      imageTag: "b2c ecommerce subscription",
    },
    {
      imageName: "Course_Hero.svg",
      imageAlt: "Course Hero logo",
      imageTag: "b2c ecommerce subscription education",
    },
    {
      imageName: "Medallia.svg",
      imageAlt: "Medallia logo",
      imageTag: "saas b2b leadgen",
    },
    {
      imageName: "NeilPatel.svg",
      imageAlt: "Neil Patel logo ",
      imageTag: "saas b2b b2c ecommerce martech",
    },
    {
      imageName: "NetSpend.svg",
      imageAlt: "Netspend logo",
      imageTag: "b2c financial subscription",
    },
    {
      imageName: "Sophos.svg",
      imageAlt: "Sophos logo",
      imageTag: "security saas b2b subscription",
    },
    {
      imageName: "Pipedrive.svg",
      imageAlt: "MLB logo",
      imageTag: "ecommerce b2c",
    },
    {
      imageName: "Progress.svg",
      imageAlt: "Progress logo",
      imageTag: "saas b2b leadgen subscription",
    },
    {
      imageName: "Fastspring.svg",
      imageAlt: "FastSpring logo",
      imageTag: "saas b2b leadgen subscription",
    },
    {
      imageName: "Skilljar.svg",
      imageAlt: "Skilljar logo",
      imageTag: "saas leadgen b2b subscription",
    },
    {
      imageName: "Mixmax.svg",
      imageAlt: "Mixmax logo",
      imageTag: "saas b2b subscription",
    },
    {
      imageName: "pipedrive_3.svg",
      imageAlt: "Pipedrive logo",
      imageTag: "saas b2b b2c leadgen ecommerce subscription",
    },
    {
      imageName: "process.st.svg",
      imageAlt: "Process Street logo",
      imageTag: "saas b2b ecommerce subscription",
    },
    {
      imageName: "Singlecare.svg",
      imageAlt: "SingleCare logo",
      imageTag: "b2c leadgen ecommerce health",
    },
    {
      imageName: "Latka.svg",
      imageAlt: "Latka logo",
      imageTag: "b2b leadgen subscription saas",
    },
    {
      imageName: "infosec.svg",
      imageAlt: "Infosec logo",
      imageTag: "security b2b leadgen subscription education",
    },
    {
      imageName: "Jive.svg",
      imageAlt: "Jive logo",
      imageTag: "saas b2b leadgen subscription",
    },
    {
      imageName: "Datto.svg",
      imageAlt: "Datto logo",
      imageTag: "saas b2b leadgen subscription",
    },
    {
      imageName: "SharpSpring.svg",
      imageAlt: "SharpSpring logo",
      imageTag: "saas b2b leadgen martech subscription",
    },
    {
      imageName: "Dreamhost.svg",
      imageAlt: "DreamHost logo",
      imageTag: "b2b b2c ecommerce subscription",
    },
    {
      imageName: "2U.svg",
      imageAlt: "2U logo",
      imageTag: "b2c leadgen education",
    },
    {
      imageName: "CherwellSoftware.svg",
      imageAlt: "Cherwell Software logo",
      imageTag: "saas b2b subscription",
    },
    {
      imageName: "OliverCabell.svg",
      imageAlt: "Oliver Cabell logo",
      imageTag: "ecommerce b2c",
    },
    {
      imageName: "Kayako.svg",
      imageAlt: "Kayako logo",
      imageTag: "saas b2b leadgen subscription",
    },
    {
      imageName: "Blue_Nile.svg",
      imageAlt: "Blue Nile logo",
      imageTag: "ecommerce b2c",
    },
    {
      imageName: "Spot_Tango.svg",
      imageAlt: "Spot & Tango logo",
      imageTag: "ecommerce b2c subscription",
    },
    {
      imageName: "VWO.svg",
      imageAlt: "VWO logo",
      imageTag: "saas b2b leadgen martech subscription",
    },
    {
      imageName: "Promescent.svg",
      imageAlt: "Promescent logo",
      imageTag: "ecommerce b2c health subscription",
    },
    {
      imageName: "ImperiaCaviar.svg",
      imageAlt: "Imperia Caviar logo",
      imageTag: "ecommerce b2c subscription",
    },
    {
      imageName: "Factor.svg",
      imageAlt: "Factor logo",
      imageTag: "ecommerce b2c subscription",
    },
    {
      imageName: "DynamoSoftware.svg",
      imageAlt: "Dynamo Software logo",
      imageTag: "saas b2b leadgen subscription",
    },
    {
      imageName: "VehicleHistory.svg",
      imageAlt: "VehicleHistory logo",
      imageTag: "saas ecommerce b2c subscription",
    },
    {
      imageName: "Tehama.svg",
      imageAlt: "Tehama logo",
      imageTag: "saas leadgen b2b subscription security",
    },
    {
      imageName: "Aurea.svg",
      imageAlt: "Aurea logo",
      imageTag: "saas b2b leadgen subscription martech",
    },
    {
      imageName: "Kajabi.svg",
      imageAlt: "Kajabi logo",
      imageTag: "saas b2b subscription education",
    },
    {
      imageName: "Taboola.svg",
      imageAlt: "Taboola logo",
      imageTag: "saas b2b martech",
    },
    {
      imageName: "Comment_Sold.svg",
      imageAlt: "CommentSold logo",
      imageTag: "saas financial b2b subscription leadgen",
    },
    {
      imageName: "mycase_u.png",
      imageAlt: "MyCase logo",
      imageTag: "saas leadgen",
    },
    // {
    //   imageName: "hellobar_u.png",
    //   imageAlt: "Hello Bar logo",
    //   imageTag: "saas leadgen martech",
    // },
    {
      imageName: "concord_u.png",
      imageAlt: "Concord logo",
      imageTag: "saas leadgen",
    },
    {
      imageName: "skincarealana_u.png",
      imageAlt: "Skincare by Alana logo",
      imageTag: "b2c ecommerce",
    },
    {
      imageName: "SimpleTexting.svg",
      imageAlt: "SimpleTexting logo",
      imageTag: "b2b leadgen saas",
    },
    {
      imageName: "PingIdentity.svg",
      imageAlt: "Ping Identity logo",
      imageTag: "b2b leadgen saas security",
    },
    {
      imageName: "Buildfire.svg",
      imageAlt: "BuildFire logo",
      imageTag: "b2c leadgen",
    },
    {
      imageName: "buildfire_webp_1.webp",
      imageAlt: "insightsoftware logo",
      imageTag: "b2b leadgen saas",
    },
    {
      imageName: "tcp_software.svg",
      imageAlt: "TimeClock Plus logo",
      imageTag: "b2b leadgen saas hcm",
    },
    {
      imageName: "caroo.svg",
      imageAlt: "Caroo logo",
      imageTag: "b2b leadgen saas",
    },
    {
      imageName: "alpha_paw.svg",
      imageAlt: "Alpha Paw logo",
      imageTag: "b2c ecommerce",
    },
    {
      imageName: "FishBowl.svg",
      imageAlt: "Fishbowl logo",
      imageTag: "b2b leadgen saas",
    },
    {
      imageName: "pandaDoc.svg",
      imageAlt: "PandaDoc logo",
      imageTag: "b2b leadgen saas",
    },
    {
      imageName: "glofox.svg",
      imageAlt: "Glofox logo",
      imageTag: "b2b leadgen saas",
    },
    {
      imageName: "CrownPeak.svg",
      imageAlt: "CrownPeak logo",
      imageTag: "b2b leadgen saas",
    },
    {
      imageName: "revolutionPrep.svg",
      imageAlt: "Revolution Prep logo",
      imageTag: "b2b leadgen saas",
    },
    {
      imageName: "kroma.svg",
      imageAlt: "Kroma logo",
      imageTag: "b2c ecommerce",
    },
    {
      imageName: "mailGun.svg",
      imageAlt: "Mailgun logo",
      imageTag: "b2b leadgen saas",
    },
    {
      imageName: "grin.svg",
      imageAlt: "GRIN logo",
      imageTag: "b2b leadgen saas",
    },
    {
      imageName: "bloomreach.svg",
      imageAlt: "Bloomreach logo",
      imageTag: "b2b leadgen saas",
    },
    {
      imageName: "splashtop.svg",
      imageAlt: "Splashtop logo",
      imageTag: "b2b leadgen saas",
    },
    {
      imageName: "socure.svg",
      imageAlt: "Socure logo",
      imageTag: "b2b leadgen saas security",
    },
    {
      imageName: "revolutionparts.svg",
      imageAlt: "RevolutionParts logo",
      imageTag: "b2c ecommerce",
    },
    {
      imageName: "launchDarkly.svg",
      imageAlt: "LaunchDarkly logo",
      imageTag: "b2b leadgen saas",
    },
    {
      imageName: "trueCommerce.svg",
      imageAlt: "TrueCommerce logo",
      imageTag: "b2b leadgen saas",
    },
    {
      imageName: "shefit.svg",
      imageAlt: "Shefit logo",
      imageTag: "b2c ecommerce",
    },
    {
      imageName: "mimecast.svg",
      imageAlt: "Mimecast logo",
      imageTag: "b2b leadgen saas security",
    },
    {
      imageName: "activeCampaign.svg",
      imageAlt: "Active Campaign logo",
      imageTag: "b2b leadgen saas",
    },
    {
      imageName: "Abott.svg",
      imageAlt: "Abott logo",
      imageTag: "health b2c leadgen",
    },
    {
      imageName: "optimoRoute.svg",
      imageAlt: "OptimoRoute logo",
      imageTag: "b2b leadgen saas",
    },
    {
      imageName: "orcaSecurity.svg",
      imageAlt: "Orca logo",
      imageTag: "b2b leadgen saas security",
    },
    {
      imageName: "insightly.svg",
      imageAlt: "Insightly logo",
      imageTag: "b2b leadgen saas",
    },
    {
      imageName: "awr.svg",
      imageAlt: "AWR logo",
      imageTag: "b2c leadgen",
    },
    {
      imageName: "skedulo.svg",
      imageAlt: "Skedulo logo",
      imageTag: "b2b leadgen saas hcm",
    },
    {
      imageName: "cleaningAuthority.svg",
      imageAlt: "The Cleaning Authority logo",
      imageTag: "b2c leadgen",
    },
    {
      imageName: "authorityBrands.svg",
      imageAlt: "Authority Brands logo",
      imageTag: "b2c leadgen",
    },
    {
      imageName: "shape.svg",
      imageAlt: "Shape logo",
      imageTag: "b2b saas",
    },
    {
      imageName: "coveo.svg",
      imageAlt: "Coveo logo",
      imageTag: "b2b saas",
    },
    {
      imageName: "dotdash.svg",
      imageAlt: "Dotdash logo",
      imageTag: "b2c",
    },
    {
      imageName: "deel.svg",
      imageAlt: "Deel logo",
      imageTag: "b2b saas hcm",
    },
    {
      imageName: "modifyhealth.svg",
      imageAlt: "ModifyHealth logo",
      imageTag: "b2b subscription",
    },
    {
      imageName: "cymatics.svg",
      imageAlt: "Cymatics logo",
      imageTag: "b2c ecommerce",
    },
    {
      imageName: "harvest.svg",
      imageAlt: "Harvest logo",
      imageTag: "b2b saas",
    },
    {
      imageName: "jackHenry.svg",
      imageAlt: "Jack Henry logo",
      imageTag: "b2b financial",
    },
    {
      imageName: "verizon.svg",
      imageAlt: "Verizon logo",
      imageTag: "b2c financial",
    },
    {
      imageName: "edX.svg",
      imageAlt: "edX logo",
      imageTag: "b2c education",
    },
    {
      imageName: "komodo.svg",
      imageAlt: "Komodo Health logo",
      imageTag: "b2b saas health",
    },
    {
      imageName: "expensify.svg",
      imageAlt: "Expensify logo",
      imageTag: "b2c b2b saas financial",
    },
    {
      imageName: "angi.svg",
      imageAlt: "Angi logo",
      imageTag: "b2c leadgen",
    },
    {
      imageName: "record360.svg",
      imageAlt: "Record360 logo",
      imageTag: "b2b saas",
    },
    {
      imageName: "cbinsights.svg",
      imageAlt: "CB Insights logo",
      imageTag: "b2b saas",
    },
    {
      imageName: "zinniz.svg",
      imageAlt: "Zinnia Health logo",
      imageTag: "b2c health",
    },
    {
      imageName: "onehour.svg",
      imageAlt: "One Hour logo",
      imageTag: "b2c leadgen",
    },
    {
      imageName: "digitalOcean.svg",
      imageAlt: "Digital Ocean logo",
      imageTag: "b2b saas",
    },
    {
      imageName: "savaTree.svg",
      imageAlt: "SavATree logo",
      imageTag: "b2c leadgen",
    },
    {
      imageName: "navigate360.svg",
      imageAlt: "Navigate360 logo",
      imageTag: "b2b saas education",
    },
    {
      imageName: "eptura.svg",
      imageAlt: "Eptura logo",
      imageTag: "b2b saas",
    },
    {
      imageName: "pluralsight.svg",
      imageAlt: "Pluralsight logo",
      imageTag: "b2b saas",
    },
    {
      imageName: "netscope.svg",
      imageAlt: "netskope logo",
      imageTag: "b2b saas security",
    },
    {
      imageName: "evisort.svg",
      imageAlt: "Evisort logo",
      imageTag: "b2b saas",
    },
    {
      imageName: "flashpoint.svg",
      imageAlt: "Flashpoint logo",
      imageTag: "b2b saas security",
    },
    {
      imageName: "theJunkLuggers.svg",
      imageAlt: "Junkluggers logo",
      imageTag: "b2c leadgen",
    },
    {
      imageName: "finQuery.svg",
      imageAlt: "FinQuery logo",
      imageTag: "b2b saas",
    },
    {
      imageName: "shipBob.svg",
      imageAlt: "ShipBob logo",
      imageTag: "b2b saas",
    },
    {
      imageName: "rugiet.svg",
      imageAlt: "Rugiet logo",
      imageTag: "b2c ecommerce health",
    },
    {
      imageName: "swiftFilings.svg",
      imageAlt: "Swyft Filings logo",
      imageTag: "b2b",
    },
    {
      imageName: "unanet.svg",
      imageAlt: "Unanet logo",
      imageTag: "b2b saas",
    },
    {
      imageName: "unily.svg",
      imageAlt: "Unily logo",
      imageTag: "b2b saas",
    },
    {
      imageName: "geotab.svg",
      imageAlt: "Geotab logo",
      imageTag: "b2b saas",
    },
    {
      imageName: "spotdraft.svg",
      imageAlt: "SpotDraft logo",
      imageTag: "b2b saas",
    },
    {
      imageName: "cybrary.svg",
      imageAlt: "Cybrary logo",
      imageTag: "b2b saas security education",
    },
    {
      imageName: "oneMain.svg",
      imageAlt: "OneMain logo",
      imageTag: "b2c",
    },
    {
      imageName: "ServiceFusion.svg",
      imageAlt: "Service Fusion logo",
      imageTag: "b2b saas",
    },
    {
      imageName: "LiveAction.svg",
      imageAlt: "LiveAction logo",
      imageTag: "b2b saas",
    },
    {
      imageName: "Harvard.svg",
      imageAlt: "Harvard logo",
      imageTag: "b2c education",
    },
    {
      imageName: "Varicent.svg",
      imageAlt: "Varicent logo",
      imageTag: "b2b saas",
    },
    {
      imageName: "Eating_Recovery_Center.svg",
      imageAlt: "Eating Recovery Center logo",
      imageTag: "b2c health",
    },
    {
      imageName: "expel.svg",
      imageAlt: "Expel logo",
      imageTag: "b2b saas security",
    },
    {
      imageName: "procurify.svg",
      imageAlt: "Procurify logo",
      imageTag: "b2b saas",
    },
    {
      imageName: "EcoOnline.svg",
      imageAlt: "EcoOnline logo",
      imageTag: "b2b saas financial",
    },
    {
      imageName: "unbounce.svg",
      imageAlt: "Unbounce logo",
      imageTag: "b2b saas martech",
    },
    {
      imageName: "arctic_wolf.svg",
      imageAlt: "Arctic Wolf logo",
      imageTag: "b2b saas security",
    },
    {
      imageName: "Assembly.svg",
      imageAlt: "Assembly logo",
      imageTag: "b2b saas",
    },
    {
      imageName: "zywave.svg",
      imageAlt: "Zywave logo",
      imageTag: "b2b saas",
    },
    {
      imageName: "relay.svg",
      imageAlt: "Relay Logo",
      imageTag: "b2b saas",
    },
    {
      imageName: "Ribbon.svg",
      imageAlt: "Ribbon logo",
      imageTag: "b2b saas",
    },
    {
      imageName: "Mend.io.svg",
      imageAlt: "Mendio logo",
      imageTag: "b2b saas security",
    },
    {
      imageName: "Deputy.svg",
      imageAlt: "Deputy Logo",
      imageTag: "b2b saas hcm",
    },
    {
      imageName: "enfusion.svg",
      imageAlt: "Enfusion Logo",
      imageTag: "b2b saas",
    },
    {
      imageName: "Velocity_Global.svg",
      imageAlt: "Velocity Global Logo",
      imageTag: "b2b saas hcm",
    },
    {
      imageName: "talentLms.svg",
      imageAlt: "TalentLMS Logo",
      imageTag: "b2b saas",
    },
    {
      imageName: "Ben.svg",
      imageAlt: "Ben Logo",
      imageTag: "b2b saas hcm",
    },
    {
      imageName: "Filevine.svg",
      imageAlt: "Filevine Logo",
      imageTag: "b2b saas",
    },
  ],
}

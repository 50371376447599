import * as React from "react"
import Layout from "../components/layout2023"
import CustomersComponent from "../components/customers/customers"
import SeoNoIndex from "../components/seo-no-index-ablyft"

const Customers = () => (
  <Layout nav={"hidden"}>
    <SeoNoIndex title="Clients" index={false} follow={false} />
    <CustomersComponent wrapperClass={"clients"} />
  </Layout>
)

export default Customers

import React, { lazy, Suspense } from "react"
import Image from "../image-component/image-2023"
import SiteNavigation from "../nav-new/nav"
import FallbackLoading from "../loader/loader"
import "./customers.scss"
import { pageContent } from "../../utilities/clientlogos"
const SliderSection = lazy(() => import("./slider"))

const sortedContent = pageContent.images.sort((a, b) =>
  a.imageAlt.localeCompare(b.imageAlt)
)

export default function Customers(props) {
  const isSSR = typeof window === "undefined"
  const filterCustomers = (filter, e) => {
    document
      .querySelectorAll(`.filter-button`)
      .forEach(element => element.classList.remove("active"))
    e.target.classList.add("active")
    document.querySelectorAll(`div.col_custom`).forEach(element => {
      if (element.getAttribute("data-filter").includes(filter))
        element.style.display = "flex"
      else element.style.display = "none"
    })
  }
  const showAllCustomers = e => {
    document
      .querySelectorAll(`.filter-button`)
      .forEach(element => element.classList.remove("active"))
    document
      .querySelectorAll(`div.col_custom`)
      .forEach(element => (element.style.display = "flex"))
    e.target.classList.add("active")
  }

  return (
    <span className="customers-wrap">
      <section className={"hero-section"}>
        <SiteNavigation />
        <div className="hero-content-wrap">
          <div className="clients-heading">Clients</div>
          <div className="clients-subheading">
            Over 200 clients served from startups to enterprise.
          </div>
        </div>
      </section>
      <section className="customer-section">
        <div className="container">
          <div className="row_custom tabbing">
            <div className="right-section">
              <ul>
                <li>
                  <button
                    onClick={e => showAllCustomers(e)}
                    className="see-all filter-button active"
                  >
                    See All
                  </button>
                </li>
                <li>
                  <button
                    className="filter-button"
                    onClick={e => filterCustomers("b2b", e)}
                  >
                    B2B
                  </button>
                </li>
                <li>
                  <button
                    className="filter-button"
                    onClick={e => filterCustomers("b2c", e)}
                  >
                    B2C
                  </button>
                </li>
                <li>
                  <button
                    className="filter-button"
                    onClick={e => filterCustomers("saas", e)}
                  >
                    SaaS
                  </button>
                </li>
                <li>
                  <button
                    className="filter-button"
                    onClick={e => filterCustomers("leadgen", e)}
                  >
                    Lead Gen
                  </button>
                </li>
                <li>
                  <button
                    className="filter-button"
                    onClick={e => filterCustomers("ecommerce", e)}
                  >
                    eCommerce
                  </button>
                </li>
                <li>
                  <button
                    className="filter-button"
                    onClick={e => filterCustomers("education", e)}
                  >
                    Edu
                  </button>
                </li>
                <li>
                  <button
                    className="filter-button"
                    onClick={e => filterCustomers("security", e)}
                  >
                    Digital Security
                  </button>
                </li>
                <li>
                  <button
                    className="filter-button"
                    onClick={e => filterCustomers("martech", e)}
                  >
                    Martech
                  </button>
                </li>
                <li>
                  <button
                    className="filter-button"
                    onClick={e => filterCustomers("hcm", e)}
                  >
                    HCM
                  </button>
                </li>
                <li>
                  <button
                    className="filter-button"
                    onClick={e => filterCustomers("financial", e)}
                  >
                    Fintech
                  </button>
                </li>
                <li>
                  <button
                    className="filter-button"
                    onClick={e => filterCustomers("health", e)}
                  >
                    Healthcare
                  </button>
                </li>
                <li>
                  <button
                    className="filter-button"
                    onClick={e => filterCustomers("subscription", e)}
                  >
                    Subscription
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="row_custom customer-content">
            {pageContent.images.map((item, index) => {
              return (
                <div
                  key={index}
                  data-filter={item.imageTag}
                  className="col_custom"
                >
                  <Image
                    sourceFolder={"customers"}
                    imgName={item.imageName}
                    className={"customer-logo"}
                    alt={item.imageAlt}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </section>
      {!isSSR && (
        <Suspense fallback={<FallbackLoading />}>
          <SliderSection />
        </Suspense>
      )}
    </span>
  )
}
